import { graphql } from 'gatsby';
import React from 'react';

import Head from '../components/Head';
import Layout from '../components/layout';
import MinimalPostCards from '../components/MinimalPostCards';
import Pagination from '../components/Pagination/pagination';
import { BlogPostsWrapper } from './templates.style';

function PncBlogList(props: any) {
  const { data, pageContext } = props;
  const posts = data.allMarkdownRemark.edges;
  const { currentPage, numPages } = pageContext;
  const isFirst = currentPage === 1;
  const isLast = currentPage === numPages;
  const createURLPath = (index: number) => `/pnc/page/${index}`;
  const PrevLink = !isFirst && createURLPath(currentPage - 1);
  const NextLink = !isLast && createURLPath(currentPage + 1);

  return (
    <Layout>
      <Head
        title={`뱅크샐러드 공식 블로그 | 컬쳐 블로그 페이지 ${currentPage}`}
        description="뱅크샐러드 공식 컬쳐 블로그입니다. 뱅크샐러드의 기술, 문화, 행사 등 최신 소식과 함께  뱅크샐러드가 겪은 다양한 경험을 공유합니다."
        keywords={[
          '뱅크샐러드 기술블로그',
          '기술블로그',
          '뱅크샐러드 채용블로그',
          '뱅크샐러드 컬쳐블로그',
          '채용블로그',
          '컬쳐블로그',
          '핀테크',
          '스타트업',
          '개발자',
          '디자이너',
          'PM',
          '기획자',
          '뱅샐',
          'banksalad',
          '이승민',
          'Github',
          '안드로이드',
          '아이폰',
          'Android',
          'iOS',
          'Frontend',
          '프론트',
          '프론트엔드',
          '프런트엔드',
          '백엔드',
          '데브옵스',
          'backend',
          'devops',
          '채용',
        ]}
      />
      <BlogPostsWrapper>
        <MinimalPostCards posts={posts} />
        <Pagination
          prevLink={PrevLink}
          nextLink={NextLink}
          currentPage={currentPage}
          totalPage={numPages}
        />
      </BlogPostsWrapper>
    </Layout>
  );
}

export default PncBlogList;

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    site {
      siteMetadata {
        title
      }
    }
    sitePage {
      path
    }
    allMarkdownRemark(
      sort: { fields: [frontmatter___weight, frontmatter___date], order: [DESC, DESC] }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { category: { eq: "pnc" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 300)
          fields {
            slug
          }
          frontmatter {
            date(formatString: "DD [<span>] MMMM [</span>]")
            title
            description
            tags
            thumbnail {
              childImageSharp {
                gatsbyImageData(width: 170, height: 170)
              }
            }
          }
        }
      }
    }
  }
`;
